@import url('https://fonts.googleapis.com/css2?family=Antonio:wght@100&family=Lato&family=Pathway+Gothic+One&display=swap');

.menubar {
  flex-direction: row;
  height: 50%;
  padding: 5px 20px;
  border-bottom: solid 1px #4b145e;
  vertical-align: middle;
  background-color: #430858;
  justify-content: center;

}

.logo {
  flex-direction: row;
  height: 5em;
  display: flex;
  gap: 12px;
  float: left;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.line {
  width: 1px;
  display: flex;
  height: 80%;
  background-color: "4b145e";
}

@media (max-width:530px) {
  .header {
    display: none !important;
  }

  .line {
    display: none !important;
  }

}

.buttons {
  height: 5em;
  display: flex;
  gap: 12px;
  float: right;
  justify-content: center;
  align-items: center;
}

.apply,
.return {
  border: none;
  text-decoration: none;
  background-color: #250431;
  padding: 10px 15px;
  font-size: 1rem;
  border-radius: 6px;
  color: white;
  float: right;
  cursor: pointer;
  transition-duration: 0.4s;
}

.apply:hover,
.return:hover {
  background-color: #250431dd;
  color: rgb(172, 188, 173);
}


.header {
  float: left;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}