@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100..900&display=swap');

.App {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0px 0px;
}

.allocations {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  flex: 1 1 50;
  justify-content: center;
}

.allocation {
  width: auto;
  height: 600px;
}

.letter {
  text-align: left;
  width: 80%;
  align-self: center;
  font-size: 1.5rem;
  font-style: italic;
  font-family: sans-serif;
  font-weight: 500;
  padding: 36px;
}

.buttons {
  height: 5em;
  display: flex;
  gap: 12px;
  float: right;
  justify-content: center;
  align-items: center;
}

.apply,
.return {
  border: none;
  text-decoration: none;
  background-color: #250431;
  padding: 10px 15px;
  font-size: 1rem;
  border-radius: 6px;
  color: white;
  float: right;
  cursor: pointer;
  transition-duration: 0.4s;
}

.apply:hover,
.return:hover {
  background-color: #250431dd;
  color: rgb(172, 188, 173);
}


.header {
  float: left;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.github {
  color: white;
  text-decoration: none;
  font-style: italic;
  text-shadow: 0 0 5px #fff;
}

@media only screen and (max-width: 768px) {

  a,
  p {
    font-size: 12px;
  }
}


* {
  font-family: "Roboto Slab", serif;
}