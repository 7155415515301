@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100..900&display=swap');

.App {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0px 0px;
}

.github {
  color: white;
  text-decoration: none;
  font-style: italic;
  text-shadow: 0 0 5px #fff;
}


.cards {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  flex: 1 1 50;
  justify-content: center;
}

.buttons {
  height: 5em;
  display: flex;
  gap: 12px;
  float: right;
  justify-content: center;
  align-items: center;
}

.apply,
.return {
  border: none;
  text-decoration: none;
  background-color: #250431;
  padding: 10px 15px;
  font-size: 1rem;
  border-radius: 6px;
  color: white;
  float: right;
  cursor: pointer;
  transition-duration: 0.4s;
}

.apply:hover,
.return:hover {
  background-color: #250431dd;
  color: rgb(172, 188, 173);
}


.header {
  float: left;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}


body {
  background-color: #250431;
  background-image: url("background_dik.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100% 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0px 20px;
  color: white;
  display: block;
}

* {
  font-family: "Roboto Slab", serif;
}

.form {
  align-items: center;
  display: flex;
}

iframe {
  padding-top: 0px;
  height: 100vh;
}