.parent {
    min-width: 150px;
    width: 275px;
    max-width: 400px;
    min-height: 100px;
    height: 185px;
    max-height: 250px;
    border-radius: 12px;
    border: 3px solid black;
    border: solid 1px #250431;
    padding: 10px;
    cursor: pointer;
    text-decoration: none;
    color: inherit;
}

.name {
    float: left;
    width: 100%;
    padding: 5% 0;
    text-align: center;
    border-radius: 12px;
    background-color: rgba(0, 0, 0, 0.52);
}